import type {
    BaseApp,
    CloseSessionEvent,
    CloseSessionEventOptions,
    CloseSessionTasks,
    OpenSessionEvent,
    OpenSessionEventOptions,
    OpenSessionTasks,
    SaveSessionTasks,
    SessionTaskQueue
} from '../App';
import type { LockContext } from '@easterngraphics/wcf/modules/utils/async';
import type { AsyncFunction } from '@egr/xbox/utils/Types';

import sortBy from 'lodash/sortBy';

import { withProgress } from '../../progress-manager/ProgressManager';

import { getCachedValue } from '@egr/xbox/utils/Cache';
import { withDebugTimer, methodWithDebugTimer, wrapDebugTimer } from '@egr/xbox/utils/Debug';
import { getEnvArray, getEnvBoolean } from '@egr/xbox/utils/ReactScriptHelper';
import { DefaultDataLanguages, getLocale, validDataLanguage } from '@egr/xbox/utils/String';
import { SyncExternalStore } from '@egr/xbox/utils/SyncExternalStore';

import { ProjectData } from '@easterngraphics/wcf/modules/eaiws/project';
import { ListenableEvent } from '@easterngraphics/wcf/modules/utils';
import { Lock } from '@easterngraphics/wcf/modules/utils/async';
import { isNotNullOrEmpty } from '@easterngraphics/wcf/modules/utils/string';

function getDataLanguages(): Array<string> {
    const dataLanguagesByEnv: Array<string> = getEnvArray('DATA_LANGUAGES');
    if (dataLanguagesByEnv.length) {
        return dataLanguagesByEnv.filter(validDataLanguage);
    }
    return DefaultDataLanguages;
}

export const DATA_LANGUAGES: Array<string> = getDataLanguages();
const EAIWS_SET_LOCALE = getEnvBoolean('EAIWS_SET_LOCALE', true);

interface SessionTaskQueueData<Arguments extends Array<unknown> = []> {
    queue: SessionTaskQueue<Arguments>;
    callbacks: Array<AsyncFunction<void, Arguments>>;
}

export function getTaskQueue<Arguments extends Array<unknown> = []>(context: string): SessionTaskQueueData<Arguments> {
    const callbacks: Array<AsyncFunction<void, Arguments>> = [];
    return {
        queue: {
            addTask: (debugLabel: string, callback: AsyncFunction): void => {
                callbacks.push(wrapDebugTimer(debugLabel, callback, {context}));
            }
        },
        callbacks
    };
}

export function executeCallbacks<Arguments extends Array<unknown> = []>(
    debugLabel: string,
    context: string,
    callbacks: Array<AsyncFunction<void, Arguments>>,
    args: Arguments
): Promise<void> {
    return withDebugTimer(
        debugLabel,
        async (): Promise<void> => {
            if (!getEnvBoolean('DISABLE_CONCURRENT_SESSION_TASKS')) {
                await Promise.all(callbacks.map((cb: AsyncFunction<void, Arguments>): Promise<void> => cb(...args)));
            } else {
                for (const callback of callbacks) {
                    await callback(...args);
                }
            }
        },
        {context}
    );
}

interface SessionManagerState {
    dataLanguage: string;
}

export abstract class SessionManager<App extends BaseApp = BaseApp> extends SyncExternalStore<SessionManagerState> {
    public readonly onChange = new ListenableEvent<void, SessionManager>(this);
    private lock: Lock = new Lock();
    private endSessionCalled: boolean = true;

    constructor(protected app: App) {
        super({ dataLanguage: 'en' });
    }

    @methodWithDebugTimer('startSession')
    public async startSession(
        addTasks?: (tasks: OpenSessionTasks) => void,
        options: OpenSessionEvent['options'] = {}
    ): Promise<void> {
        if (this.endSessionCalled === false) {
            await this.endSession(undefined, {eventReason: options?.eventReason});
        }

        const lockContext: LockContext = await this.getSessionLockContext();

        this.endSessionCalled = false;

        try {
            type TaskQueueArgument = [OpenSessionEventOptions];
            const taskQueueArgument: TaskQueueArgument = [options];

            // Generate locked task queues
            const {queue: preConnectQueue, callbacks: callbacksPreConnectQueue} = getTaskQueue<TaskQueueArgument>('startSession.preConnect');
            const {queue: loadingQueue, callbacks: callbacksLoadingQueue} = getTaskQueue<TaskQueueArgument>('startSession.loading');
            const {queue: postLoadingQueue, callbacks: callbacksPostLoadingQueue} = getTaskQueue<TaskQueueArgument>('startSession.postLoading');
            const {queue: preProcessingQueue, callbacks: callbacksPreProcessingQueue} = getTaskQueue<TaskQueueArgument>('startSession.preProcessing');
            const {queue: processingQueue, callbacks: callbacksProcessingQueue} = getTaskQueue<TaskQueueArgument>('startSession.processing');
            const {queue: postProcessingQueue, callbacks: callbacksPostProcessingQueue} = getTaskQueue<TaskQueueArgument>('startSession.postProcessing');

            const tasks: OpenSessionTasks = {
                preConnect: preConnectQueue,
                loading: loadingQueue,
                postLoading: postLoadingQueue,
                preProcessing: preProcessingQueue,
                processing: processingQueue,
                postProcessing: postProcessingQueue
            };

            // Allow the `addTasks` callback and the registered event handlers to add new tasks
            // to the task queues
            // Note: it's important that the callback is called before the `open` event is triggered
            if (addTasks !== undefined) {
                addTasks(tasks);
            }

            this.app.events.session.trigger({
                type: 'open',
                tasks,
                options
            });

            await executeCallbacks('session pre connect tasks', 'startSession', callbacksPreConnectQueue, taskQueueArgument);

            // start the actual eaiws session
            await this.startEaiwsSession(options.primaryDataLanguage);

            // Unlock the task queues and wait until all tasks are finished
            await executeCallbacks('session loading tasks', 'startSession', callbacksLoadingQueue, taskQueueArgument);
            await executeCallbacks('session post loading tasks', 'startSession', callbacksPostLoadingQueue, taskQueueArgument);
            await executeCallbacks('session pre processing tasks', 'startSession', callbacksPreProcessingQueue, taskQueueArgument);
            await executeCallbacks('session processing tasks', 'startSession', callbacksProcessingQueue, taskQueueArgument);
            await executeCallbacks('session post processing tasks', 'startSession', callbacksPostProcessingQueue, taskQueueArgument);
        } finally {
            lockContext.unlock();
        }
    }

    @methodWithDebugTimer('saveSession')
    public async saveSession(addTasks?: (tasks: SaveSessionTasks) => void, ignoreLock: boolean = false): Promise<void> {
        // FixMe: there should be a better solution
        const lockContext: LockContext | undefined = ignoreLock ? undefined : await this.getSessionLockContext();
        try {
            const {queue: updatingQueue, callbacks: callbacksUpdatingQueue} = getTaskQueue('saveSession.updating');
            const {queue: savingQueue, callbacks: callbacksSavingQueue} = getTaskQueue('saveSession.saving');

            const tasks: SaveSessionTasks = {
                updating: updatingQueue,
                saving: savingQueue,
            };

            if (addTasks != null) {
                addTasks(tasks);
            }

            this.app.events.session.trigger({
                type: 'save',
                tasks
            });

            await executeCallbacks('save session updating tasks', 'saveSession', callbacksUpdatingQueue, []);
            await executeCallbacks('save session saving tasks', 'saveSession', callbacksSavingQueue, []);
        } finally {
            if (lockContext != null) {
                lockContext.unlock();
            }
        }
    }

    @methodWithDebugTimer('endSession')
    public async endSession(addTasks?: (tasks: CloseSessionTasks) => void, options: CloseSessionEvent['options'] = {}): Promise<void> {
        const lockContext: LockContext = await this.getSessionLockContext();

        this.endSessionCalled = true;

        try {
            type TaskQueueArgument = [CloseSessionEventOptions];
            const taskQueueArgument: TaskQueueArgument = [options];

            const {queue: preCleaningQueue, callbacks: callbacksPreCleaningQueue} = getTaskQueue<TaskQueueArgument>('endSession.preCleaning');
            const {queue: cleaningQueue, callbacks: callbacksCleaningQueue} = getTaskQueue<TaskQueueArgument>('endSession.cleaning');
            const {queue: postCloseQueue, callbacks: callbacksPostCloseQueue} = getTaskQueue<TaskQueueArgument>('endSession.postClose');

            const tasks: CloseSessionTasks = {
                preCleaning: preCleaningQueue,
                cleaning: cleaningQueue,
                postClose: postCloseQueue
            };

            if (addTasks != null) {
                addTasks(tasks);
            }

            this.app.events.session.trigger({
                type: 'close',
                tasks,
                options
            });

            await executeCallbacks('end session pre cleaning tasks', 'endSession', callbacksPreCleaningQueue, taskQueueArgument);
            await executeCallbacks('end session cleaning tasks', 'endSession', callbacksCleaningQueue, taskQueueArgument);

            await this.endEaiwsSession();

            await executeCallbacks('end session postClose tasks', 'endSession', callbacksPostCloseQueue, taskQueueArgument);
        } finally {
            lockContext.unlock();
        }
    }

    public getLastSetPrimaryLanguage(): string | undefined | null {
        const cache: {primaryLanguage?: string | null, sessionId?: string} = getCachedValue(this.setLanguage, {});

        if (cache.sessionId === this.app.eaiwsSession.sessionId) {
            return cache.primaryLanguage;
        }

        return undefined;
    }

    @withProgress('sessionManager: setLanguage')
    public async setLanguage(primaryLanguage: string | null = null, updateProjectData: boolean = true, updateCatalog: boolean = true): Promise<void> {
        if (isNotNullOrEmpty(primaryLanguage)) {
            this.updateState({ dataLanguage: primaryLanguage });
        }

        const languages: Array<string> = this.getSortedDataLanguages(primaryLanguage);
        const projectData: ProjectData = new ProjectData();
        projectData.languages = languages;

        const cache: {primaryLanguage?: string | null, sessionId?: string} = getCachedValue(this.setLanguage, {});
        if (cache.primaryLanguage === primaryLanguage && cache.sessionId === this.app.eaiwsSession.sessionId) {
            if (updateProjectData) {
                await this.app.eaiwsSession.project.setProjectData(projectData);
            }
            return;
        }

        // update cache values
        cache.primaryLanguage = primaryLanguage;
        cache.sessionId = this.app.eaiwsSession.sessionId;

        await Promise.all([
            updateCatalog ? this.app.eaiwsSession.catalog.setLanguages(languages) : Promise.resolve(),
            updateProjectData ? this.app.eaiwsSession.project.setProjectData(projectData) : Promise.resolve()
        ]);

        if (this.app.eaiwsBasket.isValid) {
            await this.app.eaiwsBasket.updateSessionLanguage();
        }

        this.onChange.trigger();
    }

    /** requires env EAIWS_SET_LOCALE */
    public async setLocale(language: string): Promise<void> {
        if (EAIWS_SET_LOCALE && this.app.eaiwsSession.isValid && this.app.eaiwsSession.session != null) {
            const timezoneOffset = -(new Date().getTimezoneOffset());
            await this.app.eaiwsSession.session.setLocale(getLocale(language), undefined, timezoneOffset);
        }
    }

    public async refreshDataLanguage(defaultLanguage: string, useDefaultLanguage: boolean): Promise<void> {
        if (useDefaultLanguage) {
            return this.setLanguage(defaultLanguage);
        }

        const dataLanguageFromProject = (await this.app.eaiwsSession.project.getProjectData('JSON'))?.languages?.[0];

        if (isNotNullOrEmpty(dataLanguageFromProject)) {
            return this.setLanguage(dataLanguageFromProject, false);
        } else {
            return this.setLanguage(defaultLanguage);
        }
    }

    public getSessionLockContext(): Promise<LockContext> {
        return this.lock.acquireLock();
    }

    public getSortedDataLanguages(primaryLanguage: string | null = null): Array<string> {
        let languages: ReadonlyArray<string> = this.app.i18nextInstance.languages;

        if (primaryLanguage != null) {
            languages = [primaryLanguage, ...languages];
        }

        return sortBy(
            DATA_LANGUAGES,
            (value: string): number => {
                const pos: number = languages.indexOf(value);
                if (pos === -1) {
                    return languages.length + 1;
                }

                return pos;
            }
        );
    }

    protected abstract startEaiwsSession(primaryDataLanguage?: string): Promise<void>;

    // Note: this function can should be overwritten if the session should not be closed
    // (e.g. if the session should only be cleared and reused for loading the next project)
    protected async endEaiwsSession(): Promise<void> {
        await this.app.eaiwsSession.close();
    }
}